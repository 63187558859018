@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth !important;
}

q {
    font-style: italic;
    font-weight: 500;
}

body {
    background: white;
}
@keyframes hourglass {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(10%);
    }

    100% {
        transform: translateY(0%);
    }
}

.animate-hourglass {
    animation: hourglass 4s ease-in-out infinite;
}

li.checkmark-free::before {
  content: '\2713'; /* Unicode for checkmark */
  @apply text-blue-500 mr-2;
}

li.checkmark::before {
  content: '\2713';
  @apply text-zinc-600 mr-2;
}

li.checkmark-express::before {
  content: '\2713';
  @apply text-white mr-2;
}


#response>ul:last-of-type>li {
    filter: blur(5px);
}

#response>ul:last-of-type>li:first-child {
    filter: none;
}
